import getNewHotelPolicyList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getNewHotelPolicyList';
import delTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_delTravelPolicy";

export default {
    name: "hotelStandardList",
    data() {
        return {
            hotelPolicyList: []
        }
    },
    created() {
        this._getNewHotelPolicyList();
    },
    methods: {
        async _getNewHotelPolicyList() {
            let res = await getNewHotelPolicyList();
            this.hotelPolicyList = res.datas.results;
        },
        _deletePosition(hotelPolicy) {
            let policyIds = [];
            if (hotelPolicy) {
                switch (hotelPolicy.longShortBusiness) {
                    case 0:
                        policyIds.push(hotelPolicy.unifiedPolicy.id);
                        break;
                    case 1:
                        policyIds.push(hotelPolicy.classifyResult.shortPolicy.id);
                        policyIds.push(hotelPolicy.classifyResult.longPolicy.id);
                        break;
                    default:
                        break;
                }
            }
            this.$confirm('确定删除此政策吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delTravelPolicy({ids: policyIds}).then(res => {
                    this._getNewHotelPolicyList()
                })
            });
        },
        goToHotelCityLevel() {
            this.$router.push({name: 'admin-hotel-template-list'});
        },
        async goToEditHotelStandards(hotelPolicy) {
            let policyIds = [];
            if (hotelPolicy) {
                switch (hotelPolicy.longShortBusiness) {
                    case 0:
                        policyIds.push(hotelPolicy.unifiedPolicy.id);
                        break;
                    case 1:
                        policyIds.push(hotelPolicy.classifyResult.shortPolicy.id);
                        policyIds.push(hotelPolicy.classifyResult.longPolicy.id);
                        break;
                    default:
                        break;
                }
            }
            this.$router.push({
                name: 'admin-edit-hotel-standards',
                query: {
                    policyIds: JSON.stringify(policyIds),
                    longShortBusiness: hotelPolicy ? hotelPolicy.longShortBusiness : 0
                }
            });
        }
    }
}
